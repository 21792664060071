import React from "react";
import "./header.scss";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { isProductPage } from "app/utils/urlDataExtractor";
import LogoBrandContainer from "app/pages/.shared/LogoBrand/LogoBrandContainer";
import get from "lodash/get";
import { BackButton } from "app/pages/.shared/BackButton/BackButton";

const Header = ({
	shop,
	match,
	merchandisingCode,
	fallbackRedirectUrl,
	useBrowserBackOnProductPage,
}) => {
	const isFicheProduitPage = isProductPage(match.path);

	return (
		<header>
			<div className="header clearfix">
				<div className="page-control relative">
					<div className="vex-travel-header">
						<div className="header-content">
							<a
								className="header-home"
								target="_self"
								href={`https://shop.veepee.be/${shop}`}
							>
								<LogoBrandContainer />
							</a>
							<div id="header-links">
								<a
									id="nav-box-trending"
									target="_self"
									href={`https://www.vente-exclusive.com/${shop}/trending`}
								>
									<FormattedMessage id="header.menu.most.wanted" />
								</a>
								<div className="icon-dot small" />
								<a
									target="_self"
									href={`https://www.vente-exclusive.com//${shop}/about/jobs`}
								>
									<FormattedMessage id="header.menu.jobs" />
								</a>
								<div className="icon-dot small" />
								<a
									target="_self"
									href={`https://www.vente-exclusive.com//${shop}/Help/ContactOverview`}
								>
									<FormattedMessage id="header.menu.help" />
								</a>
							</div>
						</div>
						<div className="header-sticky">
							<div className="inner">
								<div id="navbar-top">
									<div className="header-content">
										<div id="navbar-top-left">
											<a
												className="nav-box nav-vertical"
												id="nav-box-all"
												target="_self"
												href={`https://www.vente-exclusive.com/${shop}`}
											>
												<FormattedMessage id="header.menu.all" />
											</a>
											<a
												className="nav-box nav-vertical"
												id="nav-box-women"
												target="_self"
												href={`https://www.vente-exclusive.com/${shop}#/dames`}
											>
												<FormattedMessage id="header.menu.woman" />
											</a>
											<a
												className="nav-box nav-vertical"
												id="nav-box-men"
												target="_self"
												href={`https://www.vente-exclusive.com/${shop}#/hommes`}
											>
												<FormattedMessage id="header.menu.man" />
											</a>
											<a
												className="nav-box nav-vertical"
												id="nav-box-kids"
												target="_self"
												href={`https://www.vente-exclusive.com/${shop}#/enfants`}
											>
												<FormattedMessage id="header.menu.children" />
											</a>
											<a
												className="nav-box nav-vertical"
												id="nav-box-life"
												target="_self"
												href={`https://www.vente-exclusive.com/${shop}#/lifestyle`}
											>
												<FormattedMessage id="header.menu.lifestyle" />
											</a>
											<a
												className="nav-box nav-vertical selected"
												id="nav-box-travel"
												target="_self"
												href={`https://www.vente-exclusive.com/${shop}#/travel`}
											>
												<FormattedMessage id="header.menu.travel" />
											</a>
										</div>
										<div id="navbar-top-right">
											<div className="sep first" />
											<a
												className="nav-box active"
												id="nav-box-account"
												target="_self"
												href={`https://www.vente-exclusive.com/${shop}/Account/Profile`}
											>
												<div className="icon i-man" />
												<span
													id="user-name-holder"
													className="account-first-name"
												>
													<FormattedMessage id="header.account" />
												</span>
											</a>
											<a
												className="nav-box"
												id="nav-box-orders"
												target="_self"
												href={`https://www.vente-exclusive.com/${shop}/Account/DeliveryOverview`}
											>
												<div className="icon i-orders" />
											</a>
											<a
												className="nav-box"
												id="nav-box-favorites"
												target="_self"
												href={`https://www.vente-exclusive.com/${shop}/Account/Preferences`}
											>
												<div className="icon i-favorites" />
											</a>
											<a
												className="nav-box nav-box-cart empty"
												id="nav-box-cart"
												target="_self"
												href={`https://www.vente-exclusive.com/${shop}/Cart/Edit`}
											>
												<div className="icon i-cart" />
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{isFicheProduitPage && (
				<section className="header-navigation">
					<BackButton
						forceBrowserBack={useBrowserBackOnProductPage}
						to={
							merchandisingCode
								? {
										pathname: "/merch",
										search: `?code=${merchandisingCode}`,
										merchCode: merchandisingCode,
								  }
								: {
										pathname: fallbackRedirectUrl,
								  }
						}
						label={merchandisingCode ? "general.back" : "general.back.all.sales"}
					/>
				</section>
			)}
		</header>
	);
};

Header.propTypes = {
	shop: PropTypes.string,
	merchandisingCode: PropTypes.string,
	match: PropTypes.object,
	fallbackRedirectUrl: PropTypes.string,
	useBrowserBackOnProductPage: PropTypes.bool,
};

Header.defaultProps = {
	match: {},
};

const mapStateToProps = state => {
	return {
		shop: state.shop,
		merchandisingCode: get(state, "merchandising.current.code"),
	};
};

export default connect(mapStateToProps)(Header);
